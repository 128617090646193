import React from "react"
import Layout from "../components/Layout"
import Header from "../components/HomePage/header/HeaderThree"
import ScrollToTop from "react-scroll-up"
import { Link } from "gatsby-plugin-react-intl"
import Footer from "../components/HomePage/footer/FooterTwo"
import { FormattedMessage } from "gatsby-plugin-react-intl"
import { FiChevronUp } from "react-icons/fi"
const ErrorPage = () => {
  return (
    <Layout>
      <div className="active-dark">
        {" "}
        <Header
          headerPosition="header--transparent"
          homeLink="/"
          logo="symbol-dark"
          color="color-black"
        />
      </div>

      {/* Start Page Error  */}
      <div className="error-page-inner bg_color--4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner">
                <h1 className="title theme-gradient">Wow!</h1>
                <h3 className="sub-title">
                  <FormattedMessage id="404Subtitle" />
                </h3>
                <span></span>
                <div className="error-button">
                  <Link to="/" className="rn-button-style--2 btn-solid">
                    <FormattedMessage id="404Button" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Error  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </Layout>
  )
}

export default ErrorPage
